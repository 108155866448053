import { useState, useEffect } from 'react';
import { ethers } from "ethers";
import erc20abi from "./ERC20abi.json";
import './style.css';
import bg from './bg.png';
import firerune from './firerune.png';
import waterrune from './waterrune.png';



export default function App() {

  const [walletAddress, setWalletAddress] = useState("");


  const address = "0x2CD7d41be529d76fF3Cac2074F8829326f6f511B";
  // const address = "0x96fb2a6E6B419a0244fdCF76EC811C5bf0800852"; FTM

  const [JefesInfo, setJefesInfo] = useState({
    time: "-"
  });
  //    const url = "https://bsc-dataseed.binance.org/";

  const provider = new ethers.providers.JsonRpcProvider("https://rpc.sonic.fantom.network/");
  //  const provider = new ethers.providers.Web3Provider(web3.currentProvider);
  const erc20 = new ethers.Contract(address, erc20abi, provider);
  const signer = provider.getSigner();




  //wallet connect
  async function requestAccount() {
    console.log('Requesting account ...')
    //Check if Metamask exists
    if (window.ethereum) {
      console.log('detected');
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
      } catch (error) {
        console.log('Error Connecting ..');
      }
    } else {
      console.log('Metamask not detected');
    }
  }


  //get time function 

  const getJefes = async (e) => {
    e.preventDefault();
    const releaseStartDate = await erc20.getMyJefes(walletAddress);
    setJefesInfo({
      time: String(releaseStartDate)
    });
  };

  const beanRewards = async (e) => {
    e.preventDefault();
    const addresswallet = await erc20.beanRewards(walletAddress);
    setJefesInfo({
      timereward: String(addresswallet)
    });
  };



  const sellJefes = async (e) => {
    e.preventDefault();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const erc20 = new ethers.Contract(address, erc20abi, signer);

    await erc20.sellJefes();



  };
  const hatchJefes = async (e) => {
    e.preventDefault();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const erc20 = new ethers.Contract(address, erc20abi, signer);
    const referido = walletAddress;

    await erc20.hatchJefes(referido);



  };


  const handleTransfer = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    console.log(data);
    const referido = walletAddress;
    const amountinvest = e.target[0].value;
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, erc20abi, signer)

    await contract.buyJefes(referido, { value: ethers.utils.parseEther(amountinvest) });

  };

  return (

    <div className='react--content'>
      <nav class="navbar navbar-dark bg-dark">
        <div className='container'>
<h1><b>WEB3 ADDRESS</b></h1>
          <button onClick={requestAccount}
            className="btn--connect btn-primary btn--landing">
<b>CONNECT </b> {walletAddress}
</button>
     </div>
      </nav>
      <div className='container'>
        <section className='section--bg'>
<h1 className='text--wallet'><b>JEFE RUNES<br></br> A WEB3 ADVENTURE </b> <div align="center">
                </div>
 </h1>
        </section>
<br></br>
        <div className='row'>

          <div className='col'>
            <div className='overview'>
              <div align='center'>
                <h3><b>JEFE RUNES</b></h3>
           <br></br>
     <div align="justify">
                  <p>
                 Craft  JEFE RUNES in this enigmatic web3 journey a Rune Crafter to explore decentralized wonders.</p>
       
                </div>
              </div>
            </div>
          </div>

          <div className='col'>
            <div className='overview'>
              <div align='center'>
                <h3><b>RUNES CRAFTER</b></h3>
                <br></br>
                <div align="justify">
                  <p>
 Interact & craft in order  to produce elementary  runes, conquer the knowledge of smart contract gaming interaction, master your web3 skills while crafting crytographic runes, express art & play JEFE ZOMBIE on your mobile or PC, ascend with all JEFES & build together. 
                  </p>
                </div>
              </div>
            </div>
          </div>


        </div>

        <br></br>
        <br></br>

        <div className='row'>

          <div className='col'>
            <div className='overview'>
              <div align="center">
                <h3><b>RUNES</b></h3>
                <br></br>
                <br></br>
                <div align="justify">
                  <p>There are four mysterious runes: FIRE, WATER, EARTH & SPACE. These runes uniquely contribute to the decentralized ecosystem of JEFE, supporting web2 transitions to web3 with games,  ranks within the web3 society.</p>
<br></br>
<p><b>RUNE OF <font color="#FF0420">FIRE</font></b> Stack cryptographic runes using the Rune Crafter.</p>
<br></br>
<p><b>RUNE OF <font color="#0000FF">WATER</font></b> Craft water runes within a distinct blockchain.</p>
<br></br>

<p><b>EARTH RUNE:</b> Linked to governance & <a href="http://jefetoken.com/jefezombie-tournament.php" target="_blank"><button><b><font color="#03AC13">JFFE ZOMBIE</font></b></button></a> game profile, this  rune records gaming hours & plays an important role in voting proposals.</p>
<br></br>

<div align="center">
<p><i><b>UNRELEASED</b></i></p>
</div><br></br>

<p><b>SPACE RUNE:</b> Intertwined with Web3 music,  builders & art NFTs, this ethereal rune supports the transition from Web2 to Web3 for artists & builders</p>
<br></br>

<div align="center">
 <p><i><b>UNRELEASED</b></i></p>
</div>


                </div>
              </div>
            </div>
          </div>

          <div className='col'>
            <div className='overview'>
              <div className='connect--wallet' align="center">
  <h3><b>JEFE RANK</b><br></br></h3>

          <img src="https://jefetoken.com/img/JefeTokenLogo-cg.png" width="30%"/>
                <br></br>
<br></br>
                <br></br><div align="CENTER">
<div className="text--wallet"><p> <b>{walletAddress}</b> </p></div>
 <h5 className='text--wallet'><img src={firerune} width="70%"/><b>RUNE OF FIRE</b> </h5><div align="center"> <p>Shadow</p></div>
   <a href="https://fire.jefetoken.com" target="_blank"><button className="btn--connect btn-primary btn--landing">
               <font color="white"><b>CRAFT</b></font>
                </button> </a>
<br></br><br></br>
 <h5 className='text--wallet'><img src={waterrune} width="70%"/><b>RUNE OF WATER</b> </h5><div align="center"> <p>Shadow</p></div>
       <a href="https://water.jefetoken.com" target="_blank"><button className="btn--connect btn-primary btn--landing">
           <font color="white"><b>CRAFT</b></font>
                </button></a>
<br></br>
                <br></br></div>
              </div>
            </div>
          </div>


          <div className='col'>
            <div className='overview'>
              <div align="center">
                <h3><b>IMPACT</b></h3>
                <br></br>
                <br></br>
                <br></br>
                <div align="justify">
                  <p>
Accumulate runes & master your web3 skills. Each rune signs on chain a mysterious story of creativity, governance & digital craftsmanship.<br></br>
As runes accumulate, influence in the Jefe Token Society grows. <br></br><br></br><div align="center"><h1 className='text--wallet'><b>CONFIGURATION</b></h1></div>        

<br></br>
<div align="center">
<p><i>Add network in your  crypto wallet.</i> <a href="https://docs.jefetoken.com" target="_blank" className="text--wallet">Documentation</a> </p>
<h3><b>RUNE OF  FIRE</b></h3>
<p><b>Mainnet</b></p>
<br></br>

Blockchain: Optimism<br></br>
RPC: https://mainnet.optimism.io<br></br>
CHAIN ID: 10 <br></br>
<a href="https://explorer.optimism.io" target="_blank" className="text--wallet">EXPLORER</a><br></br>

</div>


<br></br>
<div align="center">
<h3>RUNE OF WATER</h3>
<p><b>Testnet</b></p>
<br></br>

Blockchain: Sonic Fantom<br></br>
RPC: https://rpc.sonic.fantom.network/<br></br>
CHAIN ID: 64165 <br></br>
<a href="https://sonicscan.io" target="_blank" className="text--wallet">EXPLORER</a><br></br>
<br></br><br></br>
<a href="https://docs.jefetoken.com/jefe-platform/jefe-runes" target="_blank" className="text--wallet"><b>Step by Step Guide</b></a>
</div><br></br>
        



<div align="center">
<h3>RUNE OF WATER</h3>
<p><b>Mainnet</b></p>
<br></br>

Blockchain: Sonic Fantom<br></br>
RPC: https://rpc.ankr.com/fantom/<br></br>
CHAIN ID: 250 <br></br>
<a href="https://ftmscan.com" target="_blank" className="text--wallet">EXPLORER</a><br></br>
<br></br><br></br>
</div><br></br>



</p>



                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}
